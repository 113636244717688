<template>
  <div>
    <rfrdetails-tour v-if="$store.state.tour && !loading" />
    <transition name="menu-popover">
      <div v-if="pdfPreview" class="lightbox">
        <h2 class="closeX" @click="closeLightbox()">
          <a>&#215;</a>
        </h2>
        <embed
          width="90%"
          height="100%"
          title="If you are having issues viewing this PDF, your browser security settings are set to download PDFs instead of viewing in the browser. You can also try using Chrome instead of Edge."
          :src="'data:application/pdf;base64,' + this.pdfPreview"
        />
      </div>
    </transition>

    <section
      v-if="loading && !rfr"
      class="py-5 bg9 bs-shadow mobile-top"
      style="min-height: 178px"
    >
      <div data-v-5a90ec03 class="container">
        <h1 class="text-white mb0 lh1">Loading RFR....</h1>
        <br />
        <div class="hollow-dots-spinner">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
        </div>
      </div>
    </section>

    <section
      v-else
      class="py-5 bg9 bs-shadow mobile-top"
      style="min-height: 178px"
    >
      <div class="container">
        <h1 class="text-white mb0 lh1">{{ "RFR #" + rfrId }}</h1>
        <h2 class="text-white lh1"></h2>

        <p
          v-if="rfr"
          class="text-white lh1 mb0"
          v-html="rfr.testRfrs[0].explanation"
        ></p>
      </div>
    </section>

    <div v-if="loading" class="row">
      <div class="col-xs-12 col-sm-12 col-12 col-lg-12">
        <div class="flex-box-wrap">
          <div class="flex-internal">
            <atom-spinner
              :animation-duration="1000"
              :size="40"
              :color="'#575757'"
            />
            <p>Loading......</p>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="container mt30 mb30">
      <!-- col -->
      <div class="row">
        <div id="rfrdetails-step-1" class="col-lg-8">
          <div class="card-back mb10 rounded bs-shadow p-3">
            <div class="question-header d-flex align-items-center mb5 rounded">
              <div class="d-flex number-showing">
                <router-link
                  :to="{ path: $store.state.route.from.fullPath }"
                  class="mb0 lh1 mlauto list-btn dib d-flex center"
                >
                  <i class="material-icons">keyboard_arrow_left</i>
                  Back
                </router-link>
              </div>

              <div v-if="rfr" class="pag-wrapper mlauto">
                <h2>{{ rfr.testRfrs[0].requestReason }}</h2>
              </div>
            </div>
            <hr />
            <div class="info-top">
              <div class="top-row d-flex">
                <div v-if="rfr">
                  <h6 class><i class="fas fa-user"></i> Representatives</h6>
                  <p class="text-muted mb5 lh1">
                    <small v-if="rfr.orderDetails.length">
                      {{ rfr.orderDetails[0].salesman }}
                    </small>
                  </p>

                  <!--
                  <p v-for="rep in rfr.itReps" :key="rep.repId" class="text-muted mb5 lh1">
                    <small class="up">
                      {{
                      rep.lastname + ", " + rep.firstname
                      }}
                    </small>
                  </p>
                  -->
                </div>
                <div class="status ml">
                  <p v-if="rfr" class="badge badge-success">
                    {{ rfr.testRfrs[0].status }}
                  </p>
                </div>
              </div>

              <h6 class="mt20">
                <i class="fas fa-building"></i> Area Contractors
              </h6>
              <p class="text-muted mb5 lh1">
                <small v-if="rfr" class="text-muted">
                  {{ rfr.testRfrs[0].acName }}
                </small>
              </p>
              <!--
              <div v-if="rfr">
                <p v-for="ac in rfr.itac" :key="ac.acid" class="text-muted mb5 lh1">
                  <small class="up lh1">{{ ac.acName }}</small>
                </p>
              </div>
              -->
            </div>
            <hr />

            <h6 class="my-0"><i class="fas fa-file-alt"></i> Order Number</h6>
            <p class="text-muted mb20">
              <small v-if="rfr" class="up lh1">
                {{ rfr.testRfrs[0].orderNo }}
              </small>
            </p>

            <h6 class="my-0">
              <i class="fas fa-file-signature"></i> RFR Explanation
            </h6>
            <p class="text-muted mb20">
              <small v-if="rfr" class="up lh1">
                {{ rfr.testRfrs[0].explanation }}
              </small>
            </p>

            <h6 class="my-0">
              <i class="fas fa-file-alt"></i> Customer PO Number
            </h6>
            <p class="text-muted mb20">
              <small v-if="rfr.orderDetails.length" class="up lh1">
                {{ rfr.orderDetails[0].customerPoNo }}
              </small>
            </p>
            <hr />

            <h6 class="my-0"><i class="fas fa-table"></i> Date Requested</h6>
            <p class="text-muted mb20">
              <small v-if="rfr.testRfrs.length" class="up lh1">
                {{ moment(rfr.testRfrs[0].requested).format("MM-DD-YYYY") }}
              </small>
            </p>

            <h6 class="my-0"><i class="fas fa-file-alt"></i> Requestor Name</h6>
            <p class="text-muted mb20">
              <small v-if="rfr.testRfrs.length" class="up lh1">
                {{ rfr.testRfrs[0].requestedByUser }}
              </small>
            </p>

            <h6 class="my-0">
              <i class="fas fa-file-alt"></i> Requestor Email
            </h6>
            <p class="text-muted mb20">
              <small v-if="rfr.testRfrs.length" class="up lh1">
                {{ rfr.testRfrs[0].requestorEmail }}
              </small>
            </p>

            <h6 class="my-0">
              <i class="fas fa-file-alt"></i> Requestor Phone
            </h6>
            <p class="text-muted mb20">
              <small v-if="rfr.testRfrs.length" class="up lh1">
                {{ rfr.testRfrs[0].requestorPhone }}
              </small>
            </p>

            <div id="rfrdetails-step-3" class="tab-container">
              <nav>
                <div class="nav nav-tabs" id="nav-tab" role="tablist">
                  <a
                    class="nav-item nav-link active"
                    id="nav-comments-tab"
                    data-toggle="tab"
                    href="#nav-comments"
                    role="tab"
                    aria-controls="nav-home"
                    aria-selected="true"
                    >Comments</a
                  >
                  <a
                    class="nav-item nav-link"
                    id="nav-charges-tab"
                    data-toggle="tab"
                    href="#nav-details"
                    role="tab"
                    aria-controls="nav-charges"
                    aria-selected="false"
                    >Details</a
                  >
                </div>
              </nav>

              <div class="tab-content" id="nav-tabContent">
                <!-- comment content -->

                <div
                  v-if="rfr"
                  class="tab-pane fade show active"
                  id="nav-comments"
                  role="tabpanel"
                  aria-labelledby="nav-home-tab"
                >
                  <div v-if="rfr.comment.length">
                    <div
                      v-for="(comment, index) in rfr.comment"
                      v-bind:key="index"
                      class="comments"
                    >
                      <div>
                        <p class="d-flex mb0">
                          <small class="text-muted">
                            <i class="fas fa-comment"></i>
                            {{ " " }}{{ comment.senderUser }}
                          </small>
                          <small class="ml text-muted" style="display: flex">
                            <i class="material-icons mr2 fs20">schedule</i>
                            {{ " "
                            }}{{
                              moment(comment.dateSent)
                                .subtract(1, "minutes")
                                .fromNow()
                            }}
                          </small>
                          <!--
                          <small class="ml text-muted">
                            <i class="fas fa-calendar-check"></i>
                            {{ " "
                            }}{{
                              moment(comment.dateSent).format("MM-DD-YYYY")
                            }}
                          </small>
                          -->
                        </p>
                        <h6 class="my-0" style="max-width: 80%">
                          {{ comment.message }}
                        </h6>
                      </div>
                    </div>
                  </div>
                  <h6 v-else class="my-0">No Comments</h6>

                  <br />
                  <div class="form-group">
                    <label for="exampleFormControlTextarea1">Message</label>
                    <textarea
                      v-model="commentString"
                      class="form-control"
                      id="exampleFormControlTextarea1"
                      rows="3"
                    ></textarea>
                  </div>

                  <div class="flex-bottom">
                    <button
                      v-if="!commentString.length"
                      disabled
                      class="
                        mb0
                        lh1
                        mlauto
                        list-btn
                        dib
                        d-flex
                        center
                        router-link-active
                        submit-btn
                      "
                    >
                      Submit
                    </button>
                    <button
                      v-else
                      @click.prevent="insertComment()"
                      class="
                        mb0
                        lh1
                        mlauto
                        list-btn
                        dib
                        d-flex
                        center
                        router-link-active
                        submit-btn
                      "
                    >
                      Submit
                    </button>
                  </div>
                </div>

                <div
                  class="tab-pane fade"
                  id="nav-details"
                  role="tabpanel"
                  aria-labelledby="nav-contact-tab"
                >
                  Requested
                  <table
                    class="table table-striped table-bordered lines-table mb0"
                    style="margin-bottom: 20px !important"
                  >
                    <thead>
                      <tr>
                        <th scope="col">Hours</th>
                        <th scope="col">Rate</th>
                        <th scope="col">Mileage</th>
                        <th scope="col">Mileage Rate</th>
                        <th scope="col">Misc</th>
                        <th scope="col">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">
                          <small v-if="rfr" class="text-muted">{{
                            rfr.testRfrs[0].requestedHours
                          }}</small>
                        </th>
                        <td>
                          <small v-if="rfr" class="text-muted">{{
                            rfr.testRfrs[0].requestedLaborRate | currency
                          }}</small>
                        </td>
                        <td>
                          <small v-if="rfr" class="text-muted">{{
                            rfr.testRfrs[0].requestedMileage
                          }}</small>
                        </td>
                        <td>
                          <small v-if="rfr" class="text-muted">{{
                            rfr.testRfrs[0].mileageRate | currency
                          }}</small>
                        </td>
                        <td>
                          <small v-if="rfr" class="text-muted">{{
                            rfr.testRfrs[0].requestedMisc | currency
                          }}</small>
                        </td>
                        <td>
                          <small class="text-muted">{{
                            calcRequested() | currency
                          }}</small>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div v-if="rfr">
                    {{ rfr.testRfrs[0].status }}
                    <table
                      class="table table-striped table-bordered lines-table mb0"
                    >
                      <thead>
                        <tr>
                          <th scope="col">Hours</th>
                          <th scope="col">Rate</th>
                          <th scope="col">Mileage</th>
                          <th scope="col">Mileage Rate</th>
                          <th scope="col">Misc</th>
                          <th scope="col">Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">
                            <small v-if="rfr" class="text-muted">{{
                              rfr.testRfrs[0].approvedHours
                            }}</small>
                          </th>
                          <td>
                            <small v-if="rfr" class="text-muted">{{
                              rfr.testRfrs[0].approvedLaborRate | currency
                            }}</small>
                          </td>
                          <td>
                            <small v-if="rfr" class="text-muted">{{
                              rfr.testRfrs[0].approvedMileage
                            }}</small>
                          </td>
                          <td>
                            <small v-if="rfr" class="text-muted">{{
                              rfr.testRfrs[0].mileageRate | currency
                            }}</small>
                          </td>
                          <td>
                            <small v-if="rfr" class="text-muted">{{
                              rfr.testRfrs[0].approvedMisc | currency
                            }}</small>
                          </td>
                          <td>
                            <small class="text-muted">{{
                              calcApproved() | currency
                            }}</small>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="employee" class="card-back mb10 rounded bs-shadow p-3">
            <div class="edit-notes">
              <div class="top-flex" style="display: flex">
                <h5>
                  <span class="teal">SSC RFR Approval</span>
                </h5>
                <h5 style="margin-left: auto">
                  Total: {{ computeApproved | currency }}
                </h5>
              </div>
              <p>
                <small
                  >All details need to be filled in to calculate a total. If
                  Misc. is $0, insert 0.</small
                >
              </p>
              <hr />
              <div class="row">
                <div class="col-xs-6 col-md-6 col-6">
                  <div class="form-group mb10">
                    <label for="exampleInputEmail1" class="mb0">Status</label>
                    <select
                      v-if="rfr != null"
                      v-model="rfr.testRfrs[0].status"
                      class="form-control"
                      id="exampleFormControlSelect1"
                    >
                      <!--
                      <option :value="null" selected class="all-contractors">Select a lead carrier</option>
                      -->
                      <option v-for="state in rfrStates" :key="state.id">
                        {{ state.state }}
                      </option>
                    </select>
                  </div>
                  <div v-if="rfr" class="form-group mb10">
                    <label for="exampleInputEmail1" class="mb0">Hours</label>
                    <input
                      type="number"
                      class="form-control"
                      aria-describedby="Hours"
                      placeholder="Enter Hours"
                      v-model="rfr.testRfrs[0].approvedHours"
                      required
                    />
                  </div>
                  <div v-if="rfr" class="form-group mb10">
                    <label for="exampleInputEmail1" class="mb0">Rate</label>
                    <input
                      type="number"
                      class="form-control"
                      aria-describedby="Rate"
                      placeholder="Enter Rate"
                      v-model="rfr.testRfrs[0].approvedLaborRate"
                      required
                    />
                  </div>
                </div>
                <div class="col-xs-6 col-md-6 col-6">
                  <div v-if="rfr" class="form-group mb10">
                    <label for="exampleInputEmail1" class="mb0">Mileage</label>
                    <input
                      type="number"
                      class="form-control"
                      aria-describedby="Mileage"
                      placeholder="Enter Mileage"
                      v-model="rfr.testRfrs[0].approvedMileage"
                      required
                    />
                  </div>
                  <div v-if="rfr" class="form-group mb10">
                    <label for="exampleInputEmail1" class="mb0"
                      >Mileage Rate</label
                    >
                    <input
                      type="number"
                      class="form-control"
                      aria-describedby="Mileage Rate"
                      placeholder="Enter Mileage Rate"
                      v-model="rfr.testRfrs[0].mileageRate"
                      required
                    />
                  </div>

                  <div v-if="rfr" class="form-group mb10">
                    <label for="exampleInputEmail1" class="mb0">Misc</label>
                    <input
                      type="number"
                      class="form-control"
                      aria-describedby="Misc"
                      placeholder="Enter Misc"
                      @change="calcApproved"
                      v-model="rfr.testRfrs[0].approvedMisc"
                      required
                    />
                  </div>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col col-xs-12 col-12">
                  <div class="flex-bottom">
                    <button
                      @click.prevent="updateRFR"
                      class="
                        mb0
                        lh1
                        mlauto
                        list-btn
                        dib
                        d-flex
                        center
                        router-link-active
                        submit-btn
                      "
                      style="margin-left: auto"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div
            id="rfrdetails-step-2"
            class="card-back mb5 rounded bs-shadow p-3"
          >
            <ul class="list-group mb-3">
              <li
                class="
                  list-group-item
                  d-flex
                  justify-content-between
                  lh-condensed
                "
              >
                <div class>
                  <h6 class="my-0">
                    <i class="fas fa-file-alt"></i> SALESFORCE CASE #
                  </h6>

                  <div v-if="rfr.testRfrs[0].salesForce != null">
                    <small class="text-muted">
                      <p class="mb0">
                        {{ rfr.testRfrs[0].salesForce }}
                      </p>
                    </small>
                  </div>
                  <div v-else>
                    <small class="text-muted">
                      <p>Not Available</p>
                    </small>
                  </div>
                </div>
                <!--
                <span class="text-success">-$5</span>
                -->
              </li>
              <li
                class="
                  list-group-item
                  d-flex
                  justify-content-between
                  lh-condensed
                "
              >
                <div class>
                  <h6 class="my-0">
                    <i class="fas fa-file-download"></i> INVOICE(S)
                  </h6>

                  <div v-if="rfr.invoice.length">
                    <small
                      v-for="invoice in rfr.invoice"
                      :key="invoice.pdfid"
                      class="text-muted"
                    >
                      <p @click="showPDDF(invoice.pdfblog)" class="pdfLink">
                        {{
                          invoice.pdfid +
                          " - " +
                          moment(invoice.pdfdate).format("MM-DD-YYYY")
                        }}
                      </p>
                    </small>
                  </div>
                  <div v-else>
                    <small class="text-muted">
                      <p>Not Available</p>
                    </small>
                  </div>
                </div>
                <!--
                <span class="text-success">-$5</span>
                -->
              </li>
              <li
                class="
                  list-group-item
                  d-flex
                  justify-content-between
                  lh-condensed
                "
              >
                <div>
                  <div
                    v-if="rfr.attachment.length"
                    class="tab-pane fade show active"
                  >
                    <table
                      class="table table-striped table-bordered lines-table mb0"
                    >
                      <thead>
                        <tr>
                          <th scope="col">Attachments</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="doc in rfr.attachment" :key="doc.id">
                          <th scope="row">
                            <small class="text-muted">
                              
                              <a :href="doc.attachmentFullURL"
                              class="pdfLink"
                                target="_blank"
                                rel="noopener noreferrer"
                                style="cursor: pointer; text-decoration: none; color: #429da8!important;">
                                
                                {{ doc.attachmentName }}
                              </a>
                              <!--
                              
                              <a
                                @click="getDocument(doc.attachmentFullURL)"
                                target="_blank"
                                rel="noopener noreferrer"
                                style="cursor: pointer"
                                >{{ doc.attachmentName }}</a
                              >
                              -->
                              
                            </small>
                          </th>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <small v-else class="text-muted"
                    >No documents attached.</small
                  >
                  <!--
                  <h6 class="my-0">
                    <i class="fas fa-file-download"></i> Uploads
                  </h6>
                  <small class="text-muted">Brief description</small>
                  -->
                </div>
                <!--
                <span class="text-muted">$12</span>
                -->
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { AtomSpinner } from "epic-spinners";
import Avatar from "vue-avatar";
import rfrdetailsTour from "../components/Tours/rfrdetailsTour";

const auth = {
  headers: {
    Authorization: "Bearer " + localStorage.getItem("SSCUSER"),
    //Authorization: "Bearer " + wontworkqwd2iwedjqoewiudwo
  },
};

export default {
  name: "RFRDetails",
  components: {
    AtomSpinner,
    Avatar,
    rfrdetailsTour,
  },
  data() {
    return {
      ordermanageapi: process.env.VUE_APP_ORDER_MANAGE_API,
      server: process.env.VUE_APP_SERVER_URL,
      rfrId: this.$route.params.id,
      userID: this.$store.state.user.UserId,
      rfr: null,
      loading: false,
      processing: false,
      preprod: false,
      inprod: false,
      shipped: false,
      open: "",
      pdfPreview: "",
      commentString: "",
      commentString4Email: "",
      approvedCalc: this.computeApproved,
      rfrStates: [
        {
          id: 1,
          state: "Pending",
        },
        {
          id: 2,
          state: "Needs Approval",
        },
        {
          id: 3,
          state: "Approved",
        },
        {
          id: 4,
          state: "Credit Issued",
        },
        {
          id: 5,
          state: "Denied",
        },
      ],
    };
  },
  methods: {
    firstLoad: function () {
      this.loading = true;
      this.$store.commit("setActivePage", "order-manage");
      //return axios.get(this.endPointCompute, auth).then((response) => {
      return axios
        .get(`${this.ordermanageapi}/rfr/${this.rfrId}`, auth)
        .then((response) => {
          //console.log(response);
          this.rfr = response.data;
          this.loading = false;

          document
            .getElementById("mobileScrollTarget")
            .scrollIntoView({ behavior: "smooth" });

          //console.log(this.rfr.testRfrs[0].approvedMisc);
          //this.calcRequested();

          // check if approved hours is blank
          if (this.rfr && !this.rfr.testRfrs[0].approvedHours) {
            this.rfr.testRfrs[0].approvedHours = this.rfr.testRfrs[0].requestedHours;
          }
          // check if labor rate is blank
          if (this.rfr && !this.rfr.testRfrs[0].approvedLaborRate) {
            this.rfr.testRfrs[0].approvedLaborRate =
              this.rfr.testRfrs[0].requestedLaborRate;
          }
          // check if mileage is blank
          if (this.rfr &&  !this.rfr.testRfrs[0].approvedMileage) {
            this.rfr.testRfrs[0].approvedMileage =
              this.rfr.testRfrs[0].requestedMileage;
          }

          // check if misc is blank
          if (this.rfr && !this.rfr.testRfrs[0].approvedMisc) {
            this.rfr.testRfrs[0].approvedMisc =
              this.rfr.testRfrs[0].requestedMisc;
          }
        });
    },
    makeActive(item) {
      //console.log(item);
      if (this.open === item) {
        this.open = "";
      } else {
        this.open = item;
      }
    },
    showPDDF(doc) {
      this.pdfPreview = doc;
    },
    closeLightbox() {
      this.pdfPreview = "";
    },

    getDocument(link) {
      //console.log("link: " + link);
      window.open(`http://spacenet2.spacesaver.com/${link}`, "_blank");
    },
    updateRFR() {
      this.$forceUpdate();

      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + localStorage.getItem("SSCUSER"),
        },
      };
      let theDATA = [
        {
          op: "replace",
          path: "/status",
          value: this.rfr.testRfrs[0].status,
        },
        {
          op: "replace",
          path: "/approvedHours",
          value: this.rfr.testRfrs[0].approvedHours,
        },
        {
          op: "replace",
          path: "/approvedLaborRate",
          value: this.rfr.testRfrs[0].approvedLaborRate,
        },
        {
          op: "replace",
          path: "/approvedMileage",
          value: this.rfr.testRfrs[0].approvedMileage,
        },
        {
          op: "replace",
          path: "/ApprovedSecondaryTotal",
          value: this.rfr.testRfrs[0].approvedMisc,
        },
        {
          op: "replace",
          path: "/mileageRate",
          value: this.rfr.testRfrs[0].mileageRate,
        },
      ];

      return axios
        .patch(`${this.ordermanageapi}/rfr/${this.rfrId}`, theDATA, axiosConfig)
        .then((response) => {
          //console.log(response);
          this.$noty.success("Successfully updated expedited order!");
        })
        .catch((err) => {
          this.$noty.error(
            "Sorry, there was an issue updating the expedited order"
          );
        });
    },
    insertComment() {
      this.commentString4Email = this.commentString;
      var thePostDate = new Date();
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + localStorage.getItem("SSCUSER"),
        },
      };
      let theDATA = {
        parentId: this.rfrId,
        commentType: "RFR",
        senderUserID: this.userID,
        dateSent: thePostDate,
        message: this.commentString,
        subject: `RFR Comment: ${this.rfrId}`,
      };

      let rfrData = {
        rfrNum: this.rfrId,
        name: this.getName,
        emailAddress: this.getEmail,
        comment: this.commentString4Email,
      };

      let customerNotifydata = {
        message: this.commentString4Email,
        sender: this.getName,
        linkID: `rfr-details/${this.rfrId}`,
        notify: this.rfr.testRfrs[0].requestorEmail,
      };

      return axios
        .post(`${this.ordermanageapi}/comments`, theDATA, axiosConfig)
        .then((response) => {
          //console.log(response);
          this.$noty.success("Successfully added comment!");
          //this.rfr.comment.push(theDATA);
          this.commentString = "";
          this.rfr.comment = response.data;
        })
        .then(() => {
          //console.log("Send the comment");
          //if there is no comments and not an employee
          if (!this.employee) {

            //console.log("Sending an email");
            /*
            return axios
              .post(
                `${this.server}/api/v1/rfr-comment-email`,
                rfrData,
                axiosConfig
              )
              .then(() => {
                //console.log(response);
                this.commentString4Email = "";
                this.$noty.success(
                  "Successfully created a new email notification!"
                );
              })
              .catch((err) => {
                //console.log(err);
                this.$noty.error(
                  "Sorry, there was an issue making a new email notification"
                );
              });
              */

          } else {
            //console.log("Send customer email");
            return axios
              .post(
                `${this.server}/api/v1/generic-comment-email`,
                customerNotifydata,
                axiosConfig
              )
              .then(() => {
                //console.log(response);
                this.commentString4Email = "";
                //console.log("Successfully created a new email notification");
                this.$noty.success(
                  "Successfully created a new email notification!"
                );
              })
              .catch((err) => {
                //console.log(err);
                this.$noty.error(
                  "Sorry, there was an issue making a new email notification"
                );
              });
          }
        })
        .catch((err) => {
          //console.log(err);
          this.$noty.error("Sorry, there was an issue posting your comment");
        });
    },
    calcRequested() {
      if (this.rfr != null) {
        let mileage =
          this.rfr.testRfrs[0].requestedMileage *
          this.rfr.testRfrs[0].mileageRate;
        //console.log("mileage " + mileage);
        let hours =
          this.rfr.testRfrs[0].requestedHours *
          this.rfr.testRfrs[0].requestedLaborRate;
        //console.log("hours " + hours);
        let miscR = this.rfr.testRfrs[0].requestedMisc;
        let total = mileage + hours + miscR;
        return total;
      }
    },
    calcApproved() {
      if (this.rfr != null) {
        let mileage =
          this.rfr.testRfrs[0].approvedMileage *
          this.rfr.testRfrs[0].mileageRate;

        //console.log("mileage " + mileage);
        let hours =
          this.rfr.testRfrs[0].approvedHours *
          this.rfr.testRfrs[0].approvedLaborRate;

        //console.log("hours " + hours);
        //rfr.testRfrs[0].approvedMisc
        /*if (this.rfr.testRfrs[0].approvedMisc == "") {
          this.rfr.testRfrs[0].approvedMisc = 0;
        }*/

        let MISCa = this.rfr.testRfrs[0].approvedMisc;

        //console.log("misc " + MISCa);
        let subtotal = mileage + hours + parseFloat(MISCa);
        let total = subtotal;

        return total;
      }
    },
  },
  computed: {
    activeNav() {
      return this.$store.state.activePage;
    },
    computeApproved() {
      if (this.rfr != null) {
        let mileage =
          this.rfr.testRfrs[0].approvedMileage *
          this.rfr.testRfrs[0].mileageRate;
        //console.log("mileage " + mileage);
        let hours =
          this.rfr.testRfrs[0].approvedHours *
          this.rfr.testRfrs[0].approvedLaborRate;
        //console.log("hours " + hours);

        //rfr.testRfrs[0].approvedMisc
        /*if (this.rfr.testRfrs[0].approvedMisc == "") {
          this.rfr.testRfrs[0].approvedMisc = 0;
        }*/

        let MISCa = this.rfr.testRfrs[0].approvedMisc;

        //console.log("misc " + MISCa);
        let subtotal = mileage + hours + parseFloat(MISCa);
        let total = subtotal;

        return total;
      }
    },

    colorGetter() {
      return this.$store.state.colors;
    },

    getUsername() {
      return this.$store.state.user;
    },
    employee() {
      return this.$store.getters.isSpacesaverEmployee;
    },
    getEmail() {
      return this.$store.state.user.Email;
    },
    getName() {
      return (
        this.$store.state.user.FirstName + " " + this.$store.state.user.LastName
      );
    },
    approvedMisc() {
      let finalTotal = this.calcApproved + this.rfr.testRfrs[0].approvedMisc;
      return finalTotal;
    },
  },
  watch: {
    /*
      hours_value: function (newValue) {
          this.rfr.testRfrs[0].approvedHours = -parseInt(newValue);
      },
      rate_value: function (newValue) {
          this.rfr.testRfrs[0].approvedLaborRate = -parseInt(newValue);
      },
      mileage_value: function (newValue) {
          this.rfr.testRfrs[0].approvedMileage = -parseInt(newValue);
      },
      mileage_rate_value: function (newValue) {
          this.rfr.testRfrs[0].mileageRate = -parseInt(newValue);
      },
      misc_value: function (newValue) {
          this.rfr.testRfrs[0].approvedMisc = -parseInt(newValue);
      },
      */
  },
  created() {
    this.$store.commit("showNavigation");

    // console.log(this.$route.path);
  },
  mounted() {
    document
      .getElementById("mobileScrollTarget")
      .scrollIntoView({ behavior: "smooth" });
    this.firstLoad().then(function () {
      //calcProcessing();
      //calcPreProd();
      //calcInProd();
      //calcShipped();
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media all and (min-width: 992px) {
  .flex-box-wrap {
    height: 200px;
    padding: 150px 0 125px 0;
    margin: 0;
  }
}

@media all and (max-width: 991px) {
  .flex-box-wrap {
    height: 100px;
    padding: 75px 0 50px 0;
    margin: 0;
    justify-content: center;
    align-items: center;
  }
}
.card-back {
  background: #fff;
}
.article {
  background-color: #fff;
  line-height: 44px;
  padding: 20px;
  margin-bottom: 5px;
  border-top: 0;
}
.top-wrap {
  display: flex;
  align-items: flex-start;
}
.question-bottom {
  align-items: flex-end;
}
.question-header {
  background: #fff;
  color: #575757;
  display: flex !important;
}
.center {
  align-items: center;
  justify-content: center;
}
.tab-container {
  background: #f2f2f3;
  padding: 10px;
  border-radius: 4px;
}
.nav-tabs {
  border-bottom: none;
}
.nav-tabs .nav-link {
  border: none;
}
#nav-tabContent {
  background: #fff;
  padding: 20px;
  border-radius: 4px;
}
.d-flex {
  display: flex;
}
.ml {
  margin-left: auto;
}
/*
.comments:nth-child(even) {
  background: #efefef;
  padding: 10px;
  margin-bottom: 1rem;
  border-radius: 4px;
}
*/
.comments {
  border-bottom: 1px solid #efefef;
  padding: 10px 0;
}
.comments p {
  margin-bottom: 10px;
}
.comments:last-child {
  border-bottom: none;
  margin-bottom: 0;
}
.comments:last-child p {
  margin-bottom: 0;
}
.tab-container a {
  color: #429da8;
}
.mt30 {
  margin-top: 30px;
}
.badge {
  display: inline-block;
  padding: 0.5em 0.8em;
}
.progtrckr {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.progtrckr li {
  display: inline-block;
  text-align: center;
  line-height: 3.5em;
}

.progtrckr[data-progtrckr-steps="2"] li {
  width: 25%;
}
.progtrckr[data-progtrckr-steps="3"] li {
  width: 25%;
}
.progtrckr[data-progtrckr-steps="4"] li {
  width: 25%;
}
.progtrckr[data-progtrckr-steps="5"] li {
  width: 25%;
}

.progtrckr li.progtrckr-done {
  color: #fff;
  border-bottom: 4px solid #fff;
}
.progtrckr li.progtrckr-todo {
  color: #3e4651;
  border-bottom: 4px solid #3e4651;
}

.progtrckr li:after {
  content: "\00a0\00a0";
  margin-left: 28px;
  /* this is the text*/
}
.progtrckr li:before {
  position: relative;
  bottom: -2.5em;
  float: left;
  left: calc(50% - 20px);
  line-height: 1em;
}
.progtrckr li.progtrckr-done:before {
  content: "\2713";
  color: #3e4651;
  background-color: #fff;
  height: 40px;
  width: 40px;
  line-height: 40px;
  border: none;
  border-radius: 40px;
}
.progtrckr li.progtrckr-todo:before {
  /*
  content: "\039F";
  color: #fff;
  background-color: #fff;
  font-size: 2.2em;
  bottom: -1.2em;
  */
  content: "\2713";
  color: #3e4651;
  background-color: #3e4651;
  height: 2.2em;
  width: 2.2em;
  line-height: 2.2em;
  border: none;
  border-radius: 2.2em;
}
/* trackker titles */
.tracker-title-wrap {
  margin-top: 15px;
}
.trckr-title {
  padding-left: 0;
}
.trckr-title li {
  display: inline-block;
  text-align: center;
  line-height: 3.5em;
  width: 25%;
  color: #fff;
}
.trckr-title-done {
  color: #fff !important;
}
.trckr-title-todo {
  color: #3e4651 !important;
}
.badge-success {
  color: #fff;
  background-color: #3e4651;
}
@media all and (max-width: 700px) {
  .trckr-title li {
    font-size: 12px;
  }
}
.up {
  text-transform: uppercase;
}
.lines-table td,
.lines-table th {
  padding: 5px;
}
.small,
small {
  font-size: 90% !important;
}

.table td .small,
small {
  font-size: 90% !important;
}
.bol {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  background: #fff;
  padding: 10px;
  list-style-type: none;
  /*border: 1px solid #dee2e6;*/
  background: #efefef;
  margin-bottom: 5px;
  transition: background 0.8s linear;
  border-radius: 4px;
  cursor: pointer;
}
.bol:hover {
  background: #e6e5e5;
}
.parent {
  display: flex;
  align-items: center;
}
.parent i {
  transition: color 0.8s linear;
}
.parent:hover i {
  color: #000;
}
.parent:hover {
  color: #000;
}
.parent:active i {
  color: #00b4b4;
}

.child-list {
  background: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  /*transition: height 0.8s linear;*/
  height: 100%;
  max-height: 100%;
  padding: 5px;
  margin-top: 10px;
  border-radius: 4px;
}
.child-list li {
  position: relative;
  list-style-type: none;
  margin-left: 0;
  border-bottom: 1px solid #dedede;
  padding: 5px;
}
.child-list li:last-of-type {
  border-bottom: none;
}
.is-closed {
  max-height: 0;
}
.show {
  /*height: 100% !important;*/
  max-height: 100% !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to
/* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

.smooth-enter-active,
.smooth-leave-active {
  transition: opacity 0.5s;
  height: auto;
}
.smooth-enter,
.smooth-leave-to {
  opacity: 0;
}

.height-enter-active,
.height-leave-active {
  transition: height 0.5s;
  height: auto;
}
.height-enter,
.height-leave-to {
  height: 0;
}
.ship-head {
  display: flex;
  width: 100%;
}
.pallet {
  width: 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ship-number {
  width: calc(50% + 3px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media all and (max-width: 700px) {
  .pallet {
    width: 60%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .ship-number {
    width: calc(60% + 3px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.fa-arrow-circle-down {
  margin-left: auto;
}

.w100 {
  width: 100%;
}
.dflex {
  display: flex;
}
.info {
  border-top: 1px solid #dedede;
  padding-top: 5px;
  margin-top: 5px;
}
.truck-no {
  margin-left: -5px;
}
.closeX {
  top: 0;
}
.pdfLink {
  color: #429da8;
  cursor: pointer;
  margin-bottom: 0;
}
.pdfLink:hover {
  color: #00b4b4;
}
.pdfLink:active {
  color: #00b4b4;
}
.lightbox {
  z-index: 10003 !important;
}
.flex-bottom {
  display: flex;
}
.submit-btn {
  border: none !important;
  padding: 7px 12px;
}
button:disabled {
  background: rgba(0, 0, 0, 0.2) !important;
  border: none !important;
}
.list-btn {
  border: none !important;
}
</style>
